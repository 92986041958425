body,
html,
#root {
  margin: 0;
  height: 100%;
  width: 100%;
  padding: 0;
  background-color: hsl(40, 23%, 97%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

x {
  background-color: bisque;
}
